import React, { useState, useRef } from "react";
import "./Form.css";
import Button from 'react-bootstrap/Button';
import { Form as RBForm } from "react-bootstrap";
import emailjs from '@emailjs/browser';

const Form = () => {
    const form = useRef();
    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [eventType, setEventType] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [readyTime, setReadyTime] = useState("");
    const [serviceAddress, setServiceAddress] = useState("");
    const [services, setServices] = useState("");
    const [additionalComments, setAdditionalComments] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);


    const validatePhoneNumber = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setPhoneNumber(onlyDigits);
    };

    const sendEmail = (event) => {
        console.log("Rico form", form);
        emailjs
            .sendForm('service_dpjlm3m', 'template_c1tqfbz', form.current, { publicKey: 'EM4e-yPdUbb4_wVbB',})
            .then(
                () => {
                    console.log('email sent');
                    setFormSubmitted(true);
                },
                (error) => {
                    console.log('email not sent, error:', error.text);
                },
         );
    }

    return (
        <div className="Form">
            <div className="formBox">
            <div id="contactMe">{"CONTACT ME"}</div>
            <div className="formInnerBox">
                {!formSubmitted && <RBForm ref={form} onSubmit={(event) => { event.preventDefault(); event.stopPropagation(); sendEmail(event); }}>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput1">
                        <RBForm.Label>{"Full name"}</RBForm.Label>
                        <RBForm.Control required name="fullName" value={fullName} onChange={event => setFullName(event.target.value)}/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput2">
                        <RBForm.Label>{"Email address"}</RBForm.Label>
                        <RBForm.Control required name="emailAddress" value={emailAddress} onChange={event => setEmailAddress(event.target.value)} type="email" placeholder="name@example.com" />
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput3">
                        <RBForm.Label>{"Phone number"}</RBForm.Label>
                        <RBForm.Control required name="phoneNumber" maxLength="10" value={phoneNumber} onChange={validatePhoneNumber} type="tel" />
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput4">
                        <RBForm.Label>{"What type of service are you interested in?"}</RBForm.Label>
                        <RBForm.Select required name="eventType" value={eventType} onChange={event => setEventType(event.target.value)}>
                            <option>Click here to select</option>
                            <option value="Wedding">Wedding</option>
                            <option value="Special Event">Special Event</option>
                            <option value="Beauty Lesson">Beauty Lesson</option>
                        </RBForm.Select>
                    </RBForm.Group>
                    {eventType === "Other" && <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput5">
                        <RBForm.Label>{"Please specify the occasion"}</RBForm.Label>
                        <RBForm.Control name="eventDescription" as="textarea" value={eventDescription} rows={2} onChange={event => setEventDescription(event.target.value)}/>
                    </RBForm.Group>}
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput6">
                        <RBForm.Label>{"Number of services required"}</RBForm.Label>
                        <RBForm.Control name="services" value={services} as="textarea" rows={3} onChange={event => setServices(event.target.value)} placeholder="1 Bride, 3 Bridesmaids, 1 Groom, etc..."/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput6">
                        <RBForm.Label>{"Event date"}</RBForm.Label>
                        <RBForm.Control required name="eventDate" min={new Date().toISOString().split('T')[0]} value={eventDate} type="date" onChange={event => setEventDate(event.target.value)}/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput7">
                        <RBForm.Label>{"What time do you need to be ready by?"}</RBForm.Label>
                        <RBForm.Control required name="readyTime" value={readyTime} onChange={event => setReadyTime(event.target.value)}/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput8">
                        <RBForm.Label>{"Makeup service address"}</RBForm.Label>
                        <RBForm.Control required name="serviceAddress" value={serviceAddress} onChange={event => setServiceAddress(event.target.value)}/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3" controlId="exampleRBForm.ControlInput9">
                        <RBForm.Label>{"Any additional comments"}</RBForm.Label>
                        <RBForm.Control name="additionalComments" value={additionalComments} as="textarea" rows={5} onChange={event => setAdditionalComments(event.target.value)}/>
                    </RBForm.Group>
                    <RBForm.Group className="mb-3">
                        <Button className="submitButton" variant="primary" type="submit">{"Submit"}</Button>
                    </RBForm.Group>
                </RBForm>}
                {formSubmitted && <>{"Thank you for reaching out! I will get back to you shortly for further details"}</>}
            </div>
            </div>
        </div>
    )
}

export default Form;
