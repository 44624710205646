import "./Header.css";
import { Button } from "react-bootstrap";

const Header = () => {

    const contactMeButton = () => {
        return (<Button onClick={() => document.getElementById("contactMe").scrollIntoView({behavior: 'smooth'})}className="contactMeButton">{"CONTACT ME"}</Button>);
    }

    const header = () => {
        return (
            <div className="Header2">
                <div className="centerBox2">
                    <div className="MakeupBy2">
                        {"Makeup by"}
                    </div>
                    <div className="Sydney2">
                        <span className="highlight">{"Sydney Penwell"}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        {contactMeButton()}
        {header()}
        </>
    )
}

export default Header;

