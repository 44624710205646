import './App.css';
import Header from "./Header/Header";
import Info from "./Info/Info";
import Gallery from "./Gallery/Gallery";
import Form from "./Form/Form";
import Footer from "./Footer/Footer";


function App() {
  return (
      <div className="App">
        <Header />
        <Info />
        <Gallery />
        <Form />
        <Footer />
      </div>
  );
}

export default App;
