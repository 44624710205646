import "./Footer.css";
import { FaInstagram } from "react-icons/fa";
const Footer = () => {
    return (
         <>
        <div className="spacer"></div>
        <div className="footerBox">
            <div className="followMe">{"Follow me on"}</div>
            <div className="icons">
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/makeupby_sydneypenwell"><FaInstagram href="https://www.instagram.com/makeupby_sydneypenwell/" size={"2.25rem"} /></a>
                </div>
                <div className="footerName">
                    {"MAKEUP BY SYDNEY PENWELL"}
                </div>
            </div>
        </>
    )
};

export default Footer;